;(function () {
  var BREAKPOINT = 991;

  var catalog = document.querySelector('[data-catalog-btn]');
  var overlay = document.querySelector('[data-catalog-btn-overlay]');
  var subMenu = document.querySelector('[data-catalog-btn-menu]');

  var subMenuHiddenClass = "is-hidden";


  if (!subMenu) return;

  function showMenu() {
    subMenu.classList.remove(subMenuHiddenClass);
    overlay.classList.remove('hidden');
  }

  function closeMenu() {
    subMenu.classList.add(subMenuHiddenClass);
    overlay.classList.add('hidden');
  }

  function toggleMenu() {
    subMenu.classList.toggle(subMenuHiddenClass);
    overlay.classList.toggle('hidden');
  }

  catalog.addEventListener('click', function () {
    toggleMenu();
  });

  overlay.addEventListener('click', function () {
    closeMenu();
  });

  function resizeHandler(breakpoint) {
    var width = window.innerWidth;

    if (width < breakpoint) {
      closeMenu();
    }
  }

  var resizeID = null;
  window.addEventListener("resize", function () {
    clearTimeout(resizeID);
    resizeID = setTimeout(resizeHandler.bind(null, BREAKPOINT), 300);

  });

})();