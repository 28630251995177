document.addEventListener('DOMContentLoaded', function(){
  if (document.querySelector('[data-megamenu-container]') != null) {
    var mainMenu = document.querySelector('[data-megamenu-container]');
    var subMenus = mainMenu.querySelectorAll('[data-megamenu-wrap]');
    mlsMegamenu.renderCols();
    mlsMegamenu.equalHeight(mainMenu, subMenus);
  }
});

/**
 * Set current active menu item
 */
$('[data-nav-setactive-scope]').each(function () {
  var menuScope = $(this);
  var menuLinks = menuScope.find('[data-nav-setactive-link]');
  var productCategoryUrl = $('[data-product-cat-url]').attr('data-product-cat-url');

  /* Get closest parent list item to current active link */
  var activeItems = menuLinks.map(function (index, item) {
    return (item.href == window.location.href || item.href == productCategoryUrl) ? $(this).closest('[data-nav-setactive-item]') : null;
  });

  /* Get collection of list items parent to current active */
  activeItems.each(function () {
    var activeItem = $(this);
    var activeParentItems = activeItem.parents('[data-nav-setactive-item]');
    $(activeItem).add(activeParentItems).addClass('is-active');
  });

});