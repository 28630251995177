;(function ($) {
    /* Open modal window */
    $(document).on('click', '[data-modal-link-to-button]', function(){
        $.mlsModal({
            src: $(this).attr('data-href'),
            data: {
                template: $(this).data('modal-link-to-button')
            }
        });

    });

    $(document).on('click', '[data-link-to-button]', function(e){
        window.location = $(this).attr('data-href');
    });

}(jQuery));